<template>
  <div class="web-notice">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="生日提醒" name="0">
        <div class="chaxunbtn">
          <el-button size="mini" @click="setDate(1)">当天生日客</el-button>
          <el-button size="mini" @click="setDate(2)">距离3天的生日客</el-button>
          <el-button size="mini" @click="setDate(3)">距离7天的生日客</el-button>
        </div>
        <el-table :data="list" style="width: 100%" border>
          <el-table-column prop="deptName" label="门店" align="center"/>
          <el-table-column prop="name" label="头像" align="center">
            <template slot-scope="scope">
              <div style="display:flex;justify-content: center">
                <img v-if="!scope.row.headPortrait&&scope.row.sex == 0" src="../../assets/images/nan.png" class="mb-img">
                <img v-if="!scope.row.headPortrait&&scope.row.sex == 1" src="../../assets/images/nan.png" class="mb-img">
                <img v-if="!scope.row.headPortrait&&scope.row.sex == 2" src="../../assets/images/nv.png" class="mb-img">
                <img  v-if="scope.row.headPortrait" :src="scope.row.headPortrait" class="mb-img">
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="memberName" label="姓名" align="center"/>
          <el-table-column prop="name" label="性别" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.sex==0">未知</span>
              <span v-if="scope.row.sex==1">男</span>
              <span v-if="scope.row.sex==2">女</span>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机" align="center"/>
          <el-table-column prop="birthdayStr" label="生日" align="center">
            <template slot-scope="scope">
              <div >{{scope.row.birthdayStr}}</div>
              <div >(农历) {{scope.row.ylDate}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="来源" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.mbSource==0">自来</span>
                <span v-if="scope.row.mbSource==1">抖音</span>
                <span v-if="scope.row.mbSource==2">美团</span>
                <span v-if="scope.row.mbSource==3">小红书</span>
                <span v-if="scope.row.mbSource==4">支付宝</span>
                <span v-if="scope.row.mbSource==5">商城</span>
                <span v-if="scope.row.mbSource==6">员工带客</span>
                <span v-if="scope.row.mbSource==7">客带客</span>
                <span v-if="scope.row.mbSource==8">其他平台</span>
              </template>
          </el-table-column>
          <el-table-column prop="name" label="蛋糕" align="center">
            <template slot-scope="scope">
              <img src="./../../assets/images/sr.png" style="height: 38px;width: 38px;">
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="系统通知" name="1">系统通知</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";

  export default {
    name: 'notice',
    components: {},
    data() {
      return {
        activeName: '0',
        dateType: 1,
        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
      }
    },

    async created() {
      this.getBirthdayMembers()
    },
    methods: {
      handleClick() {

      },
      setDate(dateType) {
        this.dateType = dateType
        this.getBirthdayMembers()
      },
      async getBirthdayMembers() {
        let data = {
          token: getToken(),
          dateType: this.dateType,
        }
        this.loading = true
        let res = await this.$get("/sw/getBirthdayMembers", data)
        this.loading = false
        this.list=res.list;
      },
    }
  }
</script>

<style scoped>

  .web-notice {
    width: 100%;
    padding-top: 12px;
  }

  .chaxunbtn {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }
  .mb-img {
    width: 45px;
    height: 45px;
    float: left;
    border-radius: 30px;
    border: #E6E6E6 solid 1px
  }

</style>
